






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "name",
          "sort-desc": false,
        },
        content: {
          name: "Quản lý quốc gia",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
              options: {
                to(_, item) {
                  return `/app/countries/${item._id}/`;
                },
              },
            },
            code: {
              text: "Mã",
              sortable: true,
            },
            currency: {
              text: "Đơn vị tiền tệ",
              sortable: true,
            },
            countryCode: {
              text: "Country Code",
              sortable: true,
            },
            exchangeRate: userManager.checkRole(["kt", "qlk"]) && {
              text: "Tỷ giá",
              sortable: true,
              options: {
                filter: "number3",
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["admin", "kt"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              presetExchangeRate: userManager.checkRole(["kt"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật tỷ giá",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật tỉ giá",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextExchangeRate: item.nextExchangeRate,
                            nextExchangeRateStartTime: item.nextExchangeRateStartTime,
                          });
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextExchangeRate: {
                                      type: "number",
                                      attrs: {
                                        label: "Tỷ giá mới",
                                        required: true,
                                        "prepend-icon": "mdi-currency-usd",
                                        suffix: `VNĐ / ${item.currency}`,
                                        autofocus: true,
                                      },
                                    },
                                    nextExchangeRateStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển tỉ giá",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "countries",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextExchangeRate: data.nextExchangeRate,
                                        nextExchangeRateStartTime: data.nextExchangeRateStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("countries", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              return await coreApiClient.call("countries", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "countries",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("countries", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              code: {
                attrs: {
                  label: "Mã",
                  required: true,
                },
              },
              currency: {
                attrs: {
                  label: "Đơn vị tiền tệ",
                  required: true,
                },
              },
              exchangeRate: {
                attrs: {
                  label: "Tỉ giá",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              countryCode: {
                attrs: {
                  label: "Country Code",
                },
              },
              adsExchangeRateItems: {
                type: "XArrayInput",
                attrs: {
                  label: "Lịch sử tỉ giá CPQC",
                  xOptions: {
                    content: {
                      itemProperties: {
                        time: {
                          type: "XDateTimePicker",
                          attrs: {
                            label: "Ngày bắt đầu",
                            required: true,
                          },
                        },
                        value: {
                          type: "number",
                          attrs: {
                            label: "Tỷ giá",
                            required: true,
                          },
                        },
                        lazTaxPercentage: {
                          type: "number",
                          attrs: {
                            label: "Lazada VAT (%)",
                            required: true,
                          },
                        },
                        description: {
                          type: "textLarge",
                          attrs: {
                            label: "Mô tả",
                          },
                          ext: {
                            colLength: 12,
                          },
                        },
                      },
                      template: {
                        formOptions: {
                          content: {
                            colLength: 4,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            insertForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: [
                  "name",
                  "code",
                  "currency",
                  "exchangeRate",
                  "description",
                  "countryCode",
                  "adsExchangeRateItems",
                ],
              },
            },
            editForm: userManager.checkRole(["admin", "kt"]) && {
              content: {
                fieldNames: [
                  "name",
                  "code",
                  "currency",
                  "exchangeRate",
                  "description",
                  "countryCode",
                  "adsExchangeRateItems",
                ],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
